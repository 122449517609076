<template>
  <v-container class="px-0 mx-0 pa-0" fluid>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <div
                class="pa-0 px-0 ma-0"
            >
              <v-btn
                  class="mr-0"
                  color="grey darken-2"
                  outlined
                  @click="setToday"
              >
                {{ $t('generic.lang_today') }}
              </v-btn>
              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  text
                  @click="prev()"
              >
                <v-icon large>
                  mdi-chevron-left
                </v-icon>
              </v-btn>

              <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
                {{ new Date(requested_date).getDate() }}
              </v-btn>


              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  small
                  @click="next()"
              >
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <strong v-if="$refs.calendar" class="font-weight-bolder">
                {{ $refs.calendar.title }}
              </strong>
              <v-spacer></v-spacer>
            </div>
          </v-col>
          <v-col cols="6" align="right">
            <div>

              <v-btn
                  class="ma-2 white--text"
                  color="red darken-2"
                  outlined
                  @click="downloadOrderNumbersPDF()"
              >
                PDF
                <v-icon
                    right
                    dark
                >
                  mdi-file-pdf
                </v-icon>
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="fill-height pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <div style="height: 0 !important; visibility: hidden !important;">
              <v-calendar
                  ref="calendar"
                  v-model="requested_date"
                  color="primary"
                  type="day"
              ></v-calendar>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pt-0 mt-0" cols="12">

        <v-row class="pt-0 mt-0">
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-select
                :label="$t('generic.lang_SelectClass')"
                v-model="modelClass"
                :items="classes"
                :item-text="'name'"
                :item-value="'uuid'"
                @input="filterByClass"
                return-object
                outlined
                dense

            >
              <template v-slot:item="{ index, item }">
                <v-row>
                  <v-col>
                    <v-chip
                        :color=item.color
                        dark
                        label
                    >
                    </v-chip>
                  </v-col>
                  <v-col>{{ item.name }}</v-col>

                </v-row>
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" lg="6" md="6" sm="12">
            <v-select
                :label="$t('generic.lang_selectGroup')"
                v-model="modelGroup"
                :items="groups"
                :item-text="'name'"
                :item-value="'uuid'"
                @input="selectedDate"
                outlined
                dense
                multiple

            >
              <template v-slot:item="{ index, item }">
                {{ item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col class="pa-0" cols="12">
            <v-data-table
                :items="data" :headers="headers" :loading="loading"
            >
              <template v-slot:item.menu="{item}">
                <v-btn fab x-small depressed color="primary" @click="showDetails(item)">
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.crudDelete="{item}">
                <v-btn fab x-small depressed color="error" @click="deleteOrder(item)">
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- date picker dialog -->
    <v-dialog v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              @input="selectedDate"
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Details  -->
    <v-dialog v-model="details" max-width="600px" scrollable @click:outside="details=false">
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pt-2 px-2 ma-0">
          {{ $t('generic.lang_orderDetails') }}
          <v-spacer/>
          <v-btn icon @click="details=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="ma-0 my-2"/>
        <v-card-text>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col v-for="order in orders" :key="order.uuid" cols="4">
                <v-card>
                  <v-card-text>
                    <v-avatar color="grey">
                      <img
                          :src="order.imagePath"
                          alt=""
                      >
                    </v-avatar>
                    <h4>{{ order.name || 'Null' }}</h4>
                    <p class="text-right">{{ Number(order.pricePerItemTotal)|currency }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";

export default {
  name: "KantinopelOrdersList",
  components: {Datatable},
  mixins: [mixin],
  data: () => {
    return {
      loading: false,
      picker: false,
      details: false,
      type: -1,
      source: -1,
      status: -1,
      search: "",
      requested_date: "",
      data: [],
      orders: [],
      checkbox: null,
      classes: [],
      groups: [],
      nonce: 1,
      menu: false,
      modelClass: {uuid: null, name: null},
      modelGroup: [],
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
    params: function () {
      return {
        order_tuype: this.type,
        order_status: this.status,
        order_date: this.requested_date
      }
    },
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_cardNumber'),
          value: 'card_number'
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'name'
        },
        {
          text: this.$t('generic.lang_class'),
          value: 'class_name'
        },
        {
          text: this.$t('generic.lang_group'),
          value: 'group_name'
        },
        {
          text: this.$t('generic.lang_menu'),
          value: 'menu',
        },
        {
          text: '',
          value: 'crudDelete',
          sortable: false,
          width: 100,
          align: 'center'
        },

      ]
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: 'id'
        },
        {
          label: this.$t('customers.name'),
          field: 'name'
        },
        {
          label: this.$t('generic.lang_class'),
          field: 'class'
        },
        {
          label: this.$t('generic.lang_menu'),
          field: 'menu'
        },
        {
          label: this.$t('generic.lang_status'),
          field: 'status'
        }
      ]
    }
  },
  mounted() {
    this.getClasses();
    this.setToday();
    this.getOrders(this.requested_date)
  },
  methods: {
    displayEntry(entry) {

    },
    entryEdit(entry) {

    },
    deleteOrder(item) {
      this.$swal({
        title: this.$t('generic.lang_deleteOrderMsg'),
        text: this.$t('generic.lang_deleteOrderMsg'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let idsToDelete = [item.uuid];
          this.axios.post(ENDPOINTS.KANTINE.ORDER.DELETE, {
            UUIDs: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_deletedSuccessfully'),
                color: "success"
              });
              this.getOrders(this.requested_date);
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    downloadOrderNumbersPDF() {
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if (window.location.host != 'localhost' && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de') {
        let apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/canteen/orderOverviewPDF/?&bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken + "&date=" + this.requested_date, "_blank");
    },
    setToday() {
      let dt = new Date();
      this.requested_date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
      this.getOrders(this.requested_date)
    },
    prev() {
      this.$refs.calendar.prev()
      this.getOrders(this.requested_date)
    },
    next() {
      this.$refs.calendar.next()
      this.getOrders(this.requested_date)
    },
    selectedDate() {
      this.getOrders(this.requested_date)
    },
    filterByClass() {
      this.modelGroup = [];
      this.getGroups();
      this.getOrders(this.requested_date)

    },

    getClasses() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASSES.GET).then((res) => {
        this.classes = res.data.classes;
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getGroups() {
      this.loading = true;
      this.groups = [];
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUPS.GET, {
        classUUID: this.modelClass.uuid
      }).then((res) => {
        this.groups = res.data.groups;
        this.loading = false;
        this.modelGroup = null;


      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getOrders(date) {

      this.loading = true

      this.axios.post(ENDPOINTS.KANTINE.ORDER.GET, {
        date: date,
        class_uuid: this.modelClass.uuid,
        group: this.modelGroup,
      }).then(res => {
        if (res.data.status === "SUCCESS") {
          this.data = res.data.items
        } else {

        }

      }).catch(err => {

      }).finally(() => {
        this.loading = false
      })

    },
    showDetails(item) {
      this.details = true;
      this.orders = item.orders
    }
  }
}
</script>

<style scoped>

</style>
