<template>
  <v-container fluid>

    <div class="pa-0 px-0 ma-0">
      <div
          class="pa-0 px-0 ma-0"
      >
        <v-btn
            class="mr-0"
            color="grey darken-2"
            outlined
            @click="setToday"
        >
          {{$t('generic.lang_today')}}
        </v-btn>
        <v-btn
            color="grey darken-2"
            fab
            icon
            text
            @click="$refs.calendar.prev()"
        >
          <v-icon large>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <v-btn
            color="grey darken-2"
            fab
            icon
            small
            @click="$refs.calendar.next()"
        >
          <v-icon large>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <strong v-if="$refs.calendar" class="font-weight-bolder">
          {{ $refs.calendar.title }}
        </strong>
        <v-spacer></v-spacer>
      </div>
    </div>

    <v-row class="pt-0 mt-0">
      <v-col cols="12" lg="6" md="6" sm="12">
        <b-form-select v-model="order_type" :label-field="$t('generic.lang_constReservationType')" :placeholder="$t('generic.lang_type')" size="sm">
          <b-form-select-option :value="-1">{{ $t('generic.lang_allTypes') }}</b-form-select-option>
          <!--          <b-form-select-option v-for="t in this.res_types" :key="t.uuid" :value="t.uuid">{{ t.name }}
                    </b-form-select-option>-->
        </b-form-select>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12">
        <b-form-select v-model="status" :placeholder="$t('generic.lang_status')" se size="sm">
          <b-form-select-option :value="-1">{{ $t('generic.lang_allStatuses') }}</b-form-select-option>
          <b-form-select-option :value="1" class="text-capitalize">{{ $t('generic.lang_confirmed') }}</b-form-select-option>
          <b-form-select-option :value="0">{{ $t('generic.lang_unconfirmed') }}</b-form-select-option>
        </b-form-select>
      </v-col>

    </v-row>


    <v-sheet class="pa-0 ma-0" height="600">
      <v-calendar
          ref="calendar"
          v-model="value"
          :event-color="getEventColor"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :events="events"
          :type="type"
          :weekdays="weekday"
          @change="getEvents"
          @click:event="showEvent"
          @click:date="viewDay"
          @click:more="viewDay"
      ></v-calendar>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "KantinopelOrdersCalendar",
  data: () => {
    return {
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
        {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
        {text: 'Mon - Fri', value: [1, 2, 3, 4, 5]},
        {text: 'Mon, Wed, Fri', value: [1, 3, 5]},
      ],
      value: '',
      selectedEvent: null,
      selectedElement: null,
      type: "month",
      order_type: -1,
      status: -1,
      events: [],
    }
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      const events = []
      this.events = events
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.value = '';
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  }
}
</script>

<style scoped>

</style>