<template>
  <div>
    <page-title :heading="$t('generic.lang_orders')" :icon=icon :subheading="$t('generic.lang_orders')"
    ></page-title>
    <div class="app-main__inner">
      <kantinopel-orders-component/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import KantinopelOrdersComponent from "@/components/kantinopel/KantinopelOrdersComponent";

export default {
  name: "KantinopelOrders",
  components: {
    PageTitle,
    KantinopelOrdersComponent
  },
  data: () => ({
    icon: 'pe-7s-shuffle icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>